<template>
  <b-container
      id="printMe"
      class="bv-example-row mb-3"
      fluid
  >
    <hr>
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <b-row class="mb-1">
      <b-col cols="12">
        <b-input-group class="float-right flat-date justify-content-end"
                       :style="{width: width < 900 ? '100%!important':''}"
        >
          <b-input-group-prepend>
            <flat-pickr
                v-model="start"
                class="form-control"
            />
          </b-input-group-prepend>
          <b-button
              variant="outline-primary"
              disabled
              class="disabledColor"
          >
            <feather-icon icon="CalendarIcon"/>
          </b-button>
          <b-input-group-append>
            <flat-pickr
                v-model="end"
                class="form-control"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <div v-if="!spinner">
      <b-card-actions
          class="cari-liste-card"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row" :style="{backgroundColor: this.$store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block">Detaylı Kasa ve Otel Raporu
                <small v-if="isDate">({{ start + ' ' + end }})</small></h4>
            </b-col>
          </div>
        </b-row>
        <hr v-if="isDate">
        <b-row
            v-if="isDate"
            class="mt-2 mb-1"
        >
          <b-col
              md="2"
              sm="6"
              class="mb-2 mb-md-0 mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    class="pull-up"
                    size="48"
                    variant="bgSuccess"
                >
                  <feather-icon
                      size="24"
                      icon="TrendingUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ this.$store.getters.kasaToplamGelir + ' TL' }}
                </h4>
                <p class="font-small-3 mb-0">
                  Toplam Gelir
                </p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="2"
              sm="6"
              class="mb-2 mb-md-0 mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    class="pull-up"
                    size="48"
                    variant="bgInfo"
                >
                  <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ this.$store.getters.kasaToplamHavale + ' TL' }}
                </h4>
                <p class="font-small-3 mb-0">
                  Toplam Havale
                </p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="2"
              sm="6"
              class="mb-2 mb-md-0 mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    class="pull-up"
                    size="48"
                    variant="bgDanger"
                >
                  <feather-icon
                      size="24"
                      icon="MinusIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ this.$store.getters.kasaToplamGider + ' TL' }}
                </h4>
                <p class="font-small-3 mb-0">
                  Toplam Gider
                </p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="2"
              sm="6"
              class="mb-2 mb-md-0 mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    class="bg-warning bg-lighten-2 pull-up"
                >
                  <feather-icon
                      size="24"
                      icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ this.$store.getters.kasaToplamKonaklama }}
                </h4>
                <p class="font-small-3 mb-0">
                  Toplam Konaklama
                </p>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="2"
              sm="6"
              class="mb-2 mb-md-0 mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    class="bg-success bg-darken-2 pull-up"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ this.$store.getters.kasaToplamOnline }}
                </h4>
                <p class="font-small-3 mb-0">
                  Toplam Online Konuk
                </p>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-actions>
      <hr v-if="isDate">
      <b-card-actions
          v-if="isDate"
          ref="refreshCard"
          class="cardActions"
          action-refresh
          @refresh="refreshStop('refreshCard')"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block">Detaylı Liste</h4>
            </b-col>
          </div>
        </b-row>
        <hr class="myHr">
        <b-tabs v-model="currentTab">
          <b-tab
              active
              @click="gelirShow"
          >
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Gelir kayıtları</span>
            </template>
            <b-row>
              <b-col
                  cols="6"
                  sm="6"
                  md="6"
                  class="my-1"
              >
                <b-input-group>
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="gelirPerPage"
                        :options="gelirPerPageOp"
                        size="sm"
                        @change="gelirPerPageChange($event)"
                    />
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col
                  cols="6"
                  lg="6"
                  md="6"
                  class="my-1 d-flex justify-content-end arama test"
              >
                <b-input-group
                    size="sm"
                    class="searchGroup"
                >
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="gelirSearch"
                      type="search"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                hover
                striped
                empty-text=""
                :items="gelirListesi"
                :fields="gelirFields"
                :per-page="gelirPerPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
                responsive
            >
              <template #cell(odaKodu)="data">
                {{ data.item.odaKodu }}
              </template>
              <template #cell(rezervasyonNo)="data">
                <a @click="rezGo(data.item.rezervasyonNo)">{{
                    data.item.rezervasyonNo
                  }}</a>
              </template>
              <template #cell(adi)="data">
                {{ data.item.adi }} {{ data.item.soyAdi }}
              </template>
              <template #cell(tarife)="data">
                {{ tarifeGet(data.item.tarife) }}
              </template>
              <template #cell(fiyat)="data">
                {{ data.item.fiyat }} TL
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih.slice(0, -8) }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
              <template #cell(checkOut)="data">
                <b-badge
                    pill
                    :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                >
                  <strong>
                    {{
                      data.item.odemeDurum == 1
                          ? 'Tahsilat Tam / Aktif Konaklama'
                          : 'Tahsilat Bek / Aktif Konaklama'
                    }}
                  </strong>
                </b-badge>
              </template>
            </b-table>
            <hr>
            <b-row class="mt-50">
              <b-col lg="6" md="6" sm="12" cols="12">
                <p class="text-muted">
                  Toplam {{ gelirTotalRows }} Kayıt Bulundu
                </p>
              </b-col>
              <div lg="6" md="6" sm="12" cols="12" class="demo-spacing-0">
                <b-col cols="6">
                  <b-pagination
                      v-model="gelirPage"
                      :align="'rigth'"
                      :per-page="gelirPerPage"
                      :total-rows="gelirTotalRows"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="gelirCurrentPageChange($event)"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
          <b-tab @click="havaleShow">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Havale kayıtları</span>
            </template>
            <b-row>
              <b-col
                  sm="3"
                  md="3"
                  class="my-1"
              >
                <b-input-group>
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="havalePerPage"
                        :options="havalePerPageOp"
                        size="sm"
                        @change="havalePerPageChange($event)"
                    />
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col
                  lg="3"
                  class="my-1 d-flex justify-content-end arama"
              >
                <b-input-group
                    size="sm"
                    class="searchGroup"
                >
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="havaleSearch"
                      type="search"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                hover
                striped
                empty-text=""
                :items="havaleListesi"
                :fields="havaleFields"
                :per-page="havalePerPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
            >
              <template #cell(odaKodu)="data">
                {{ data.item.odaKodu }}
              </template>
              <template #cell(rezervasyonNo)="data">
                <a @click="rezGo(data.item.rezervasyonNo)">{{
                    data.item.rezervasyonNo
                  }}</a>
              </template>
              <template #cell(adi)="data">
                {{ data.item.adi }} {{ data.item.soyAdi }}
              </template>
              <template #cell(tarife)="data">
                {{ tarifeGet(data.item.tarife) }}
              </template>
              <template #cell(fiyat)="data">
                {{ data.item.fiyat }} TL
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih.slice(0, -8) }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
              <template #cell(checkOut)="data">
                <b-badge
                    pill
                    :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                >
                  <strong>
                    {{
                      data.item.odemeDurum == 1
                          ? 'Tahsilat Tam / Aktif Konaklama'
                          : 'Tahsilat Bek / Aktif Konaklama'
                    }}
                  </strong>
                </b-badge>
              </template>
            </b-table>
            <hr>
            <b-row class="mt-50">
              <b-col lg="6" md="6" sm="12" cols="12">
                <p class="text-muted">
                  Toplam {{ havaleTotalRows }} Kayıt Bulundu
                </p>
              </b-col>
              <div lg="6" md="6" sm="12" cols="12" class="demo-spacing-0">
                <b-col cols="6">
                  <b-pagination
                      v-model="havalePage"
                      :align="'rigth'"
                      :per-page="havalePerPage"
                      :total-rows="havaleTotalRows"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="havaleCurrentPageChange($event)"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
          <b-tab @click="giderShow">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Gider kayıtları</span>
            </template>
            <b-row>
              <b-col
                  sm="3"
                  md="3"
                  class="my-1"
              >
                <b-input-group>
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="giderPerPage"
                        :options="giderPerPageOp"
                        size="sm"
                        @change="giderPerPageChange($event)"
                    />
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col
                  lg="3"
                  class="my-1 d-flex justify-content-end arama"
              >
                <b-input-group
                    size="sm"
                    class="searchGroup"
                >
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="giderSearch"
                      type="search"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                hover
                striped
                empty-text=""
                :items="giderListesi"
                :fields="giderFields"
                :per-page="giderPerPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
            >
              <template #cell(odaKodu)="data">
                {{ data.item.odaKodu }}
              </template>
              <template #cell(rezervasyonNo)="data">
                <a @click="rezGo(data.item.rezervasyonNo)">{{
                    data.item.rezervasyonNo
                  }}</a>
              </template>
              <template #cell(adi)="data">
                {{ data.item.adi }} {{ data.item.soyAdi }}
              </template>
              <template #cell(tarife)="data">
                {{ tarifeGet(data.item.tarife) }}
              </template>
              <template #cell(fiyat)="data">
                {{ data.item.fiyat }} TL
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih.slice(0, -8) }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
              <template #cell(checkOut)="data">
                <b-badge
                    pill
                    :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                >
                  <strong>
                    {{
                      data.item.odemeDurum == 1
                          ? 'Tahsilat Tam / Aktif Konaklama'
                          : 'Tahsilat Bek / Aktif Konaklama'
                    }}
                  </strong>
                </b-badge>
              </template>
            </b-table>
            <hr>
            <b-row class="mt-50">
              <b-col lg="6" md="6" sm="12" cols="12">
                <p class="text-muted">
                  Toplam {{ giderTotalRows }} Kayıt Bulundu
                </p>
              </b-col>
              <div lg="6" md="6" sm="12" cols="12" class="demo-spacing-0">
                <b-col cols="6">
                  <b-pagination
                      v-model="giderPage"
                      :align="'rigth'"
                      :per-page="giderPerPage"
                      :total-rows="giderTotalRows"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="giderCurrentPageChange($event)"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
          <b-tab @click="konaklamaShow">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Konaklama kayıtları</span>
            </template>
            <b-row>
              <b-col
                  sm="3"
                  md="3"
                  class="my-1"
              >
                <b-input-group>
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="konaklamaPerPage"
                        :options="konaklamaPerPageOp"
                        size="sm"
                        @change="konaklamaPerPageChange($event)"
                    />
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col
                  lg="3"
                  class="my-1 d-flex justify-content-end arama"
              >
                <b-input-group
                    size="sm"
                    class="searchGroup"
                >
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="konaklamaSearch"
                      type="search"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                hover
                striped
                empty-text=""
                :items="konaklamaListesi"
                :fields="konaklamaFields"
                :per-page="konaklamaPerPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
            >
              <template #cell(odaKodu)="data">
                {{ data.item.odaKodu }}
              </template>
              <template #cell(rezervasyonNo)="data">
                <a @click="rezGo(data.item.rezervasyonNo)">{{
                    data.item.rezervasyonNo
                  }}</a>
              </template>
              <template #cell(adi)="data">
                {{ data.item.adi }} {{ data.item.soyAdi }}
              </template>
              <template #cell(tarife)="data">
                {{ tarifeGet(data.item.tarife) }}
              </template>
              <template #cell(fiyat)="data">
                {{ data.item.fiyat }} TL
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih.slice(0, -8) }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
              <template #cell(checkOut)="data">
                <b-badge
                    pill
                    :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                >
                  <strong>
                    {{
                      data.item.odemeDurum == 1
                          ? 'Tahsilat Tam / Aktif Konaklama'
                          : 'Tahsilat Bek / Aktif Konaklama'
                    }}
                  </strong>
                </b-badge>
              </template>
            </b-table>
            <hr>
            <b-row class="mt-50">
              <b-col lg="6" md="6" sm="12" cols="12">
                <p class="text-muted">
                  Toplam {{ konaklamaTotalRows }} Kayıt Bulundu
                </p>
              </b-col>
              <div class="demo-spacing-0" lg="6" md="6" sm="12" cols="12">
                <b-col cols="6">
                  <b-pagination
                      v-model="konaklamaPage"
                      :align="'rigth'"
                      :per-page="konaklamaPerPage"
                      :total-rows="konaklamaTotalRows"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="konaklamaCurrentPageChange($event)"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
          <b-tab @click="onlineShow">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Online konaklama kayıtları</span>
            </template>
            <b-row>
              <b-col
                  sm="3"
                  md="3"
                  class="my-1"
              >
                <b-input-group>
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="onlinePerPage"
                        :options="onlinePerPageOp"
                        size="sm"
                        @change="onlinePerPageChange($event)"
                    />
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col
                  lg="3"
                  class="my-1 d-flex justify-content-end arama"
              >
                <b-input-group
                    size="sm"
                    class="searchGroup"
                >
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="onlineSearch"
                      type="search"
                  />
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                hover
                striped
                empty-text=""
                :items="onlineListesi"
                :fields="onlineFields"
                :per-page="onlinePerPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
            >
              <template #cell(odaKodu)="data">
                {{ data.item.odaKodu }}
              </template>
              <template #cell(rezervasyonNo)="data">
                <a @click="rezGo(data.item.rezervasyonNo)">{{
                    data.item.rezervasyonNo
                  }}</a>
              </template>
              <template #cell(adi)="data">
                {{ data.item.adi }} {{ data.item.soyAdi }}
              </template>
              <template #cell(tarife)="data">
                {{ tarifeGet(data.item.tarife) }}
              </template>
              <template #cell(fiyat)="data">
                {{ data.item.fiyat }} TL
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih.slice(0, -8) }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
              <template #cell(checkOut)="data">
                <b-badge
                    pill
                    :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                >
                  <strong>
                    {{
                      data.item.odemeDurum == 1
                          ? 'Tahsilat Tam / Aktif Konaklama'
                          : 'Tahsilat Bek / Aktif Konaklama'
                    }}
                  </strong>
                </b-badge>
              </template>
            </b-table>
            <hr>
            <b-row class="mt-50">
              <b-col lg="6" md="6" sm="12" cols="12">
                <p class="text-muted">
                  Toplam {{ onlineTotalRows }} Kayıt Bulundu
                </p>
              </b-col>
              <div class="demo-spacing-0" lg="6" md="6" sm="12" cols="12">
                <b-col cols="6">
                  <b-pagination
                      v-model="onlinePage"
                      :align="'rigth'"
                      :per-page="onlinePerPage"
                      :total-rows="onlineTotalRows"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="onlineCurrentPageChange($event)"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card-actions>
    </div>
  </b-container>
</template>
<script>
import {
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BCardTitle,
  BFormTextarea,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTabs,
  BTab,
  BModal,
  VBModal,
  BPagination,
} from 'bootstrap-vue'
import _ from 'lodash'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BFormSelect,
    BCardActions,
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BCardTitle,
    vSelect,
    BFormTextarea,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTabs,
    BTab,
    BModal,
    VBModal,
    BPagination,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      spinner: true,
      currentTab: 0,
      isDate: false,
      start: '',
      end: '',
      sortDirection: 'asc',
      sortDesc: false,
      sortBy: '',
      filterOn: [],
      gelirSearch: '',
      gelirPage: 1,
      gelirPerPage: 10,
      gelirPerPageOp: [5, 10, 25, 50, 100],
      gelirFields: [
        {
          key: 'rezervasyonNo',
          label: 'REZ NO',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'fisNo',
          label: 'FİŞ NO',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'departmanAdi',
          label: 'DEPARTMAN',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'user',
          label: 'İŞLEM SAHİBİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'odemeTuru',
          label: 'KAYIT TÜRÜ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'adi',
          label: 'MÜŞTERİ ADI',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'gelir',
          label: 'TUTAR',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'aciklama',
          label: 'AÇIKLAMA',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'kayitTarih',
          label: 'TARİH',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      havaleSearch: '',
      havalePage: 1,
      havalePerPage: 10,
      havalePerPageOp: [5, 10, 25, 50, 100],
      havaleFields: [
        {
          key: 'rezervasyonNo',
          label: 'REZ NO',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'fisNo',
          label: 'FİŞ NO',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'departmanAdi',
          label: 'DEPARTMAN',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'user',
          label: 'İŞLEM SAHİBİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'adi',
          label: 'MÜŞTERİ ADI',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'gelir',
          label: 'TUTAR',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'aciklama',
          label: 'AÇIKLAMA',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'kayitTarih',
          label: 'TARİH',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      giderSearch: '',
      giderPage: 1,
      giderPerPage: 10,
      giderPerPageOp: [5, 10, 25, 50, 100],
      giderFields: [
        {
          key: 'fisNo',
          label: 'FİŞ NO',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'user',
          label: 'İŞLEM SAHİBİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'tutar',
          label: 'TUTAR',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'aciklama',
          label: 'AÇIKLAMA',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'kayitTarih',
          label: 'TARİH',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      konaklamaSearch: '',
      konaklamaPage: 1,
      konaklamaPerPage: 10,
      konaklamaPerPageOp: [5, 10, 25, 50, 100],
      konaklamaFields: [
        {
          key: 'odaKodu',
          label: 'ODA',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'rezervasyonNo',
          label: 'REZ NO',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'adi',
          label: 'KONUK İSMİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'girisTarih',
          label: 'GİRİŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'cikisTarih',
          label: 'ÇIKIŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'checkOut',
          label: 'DURUM',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
      ],
      onlineSearch: '',
      onlinePage: 1,
      onlinePerPage: 10,
      onlinePerPageOp: [5, 10, 25, 50, 100],
      onlineFields: [
        {
          key: 'odaKodu',
          label: 'ODA',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'rezervasyonNo',
          label: 'REZ NO',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'adi',
          label: 'KONUK İSMİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'girisTarih',
          label: 'GİRİŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'cikisTarih',
          label: 'ÇIKIŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'checkOut',
          label: 'DURUM',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
      ],
    }
  },
  computed: {
    gelirListesi() {
      if (this.$store.getters.kasaGelirListesi) {
        return this.$store.getters.kasaGelirListesi
      }
    },
    gelirTotalRows() {
      if (this.$store.getters.kasaGelirSayac) {
        return this.$store.getters.kasaGelirSayac
      }
    },
    gelirToplamKayit() {
      if (this.$store.getters.kasaGelirListesi) {
        return this.$store.getters.kasaGelirListesi.length
      }
    },
    departmanListOp() {
      if (this.$store.getters.departmanList) {
        return this.$store.getters.departmanList
      }
    },
    gelirKayitlari() {
      return _.orderBy(
          this.$store.getters.gelirKayilariReturn,
          'departman',
          'desc',
      )
    },
    havaleListesi() {
      if (this.$store.getters.kasaHavaleListesi) {
        return this.$store.getters.kasaHavaleListesi
      }
    },
    havaleTotalRows() {
      if (this.$store.getters.kasaHavaleSayac) {
        return this.$store.getters.kasaHavaleSayac
      }
    },
    giderListesi() {
      if (this.$store.getters.kasaGiderListesi) {
        return this.$store.getters.kasaGiderListesi
      }
    },
    giderTotalRows() {
      if (this.$store.getters.kasaGiderSayac) {
        return this.$store.getters.kasaGiderSayac
      }
    },
    konaklamaListesi() {
      if (this.$store.getters.kasaKonukListesi) {
        return this.$store.getters.kasaKonukListesi
      }
    },
    konaklamaTotalRows() {
      if (this.$store.getters.kasaKonukSayac) {
        return this.$store.getters.kasaKonukSayac
      }
    },
    onlineListesi() {
      if (this.$store.getters.kasaOnlineListesi) {
        return this.$store.getters.kasaOnlineListesi
      }
    },
    onlineTotalRows() {
      if (this.$store.getters.kasaOnlineSayac) {
        return this.$store.getters.kasaOnlineSayac
      }
    },
  },
  watch: {
    havaleSearch(newVal) {
      this.havalePage = 1
      this.isDate = true
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            start: this.start,
            end: this.end,
            baslangic: 0,
            uzunluk: this.havalePerPage,
            searchKey: newVal,
            filtre: true,
            tablo: 'havaleListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    gelirSearch(newVal) {
      this.gelirPage = 1
      this.isDate = true
      const veri = newVal * this.gelirPerPage - this.gelirPerPage
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            start: this.start,
            end: this.end,
            baslangic: 0,
            uzunluk: this.gelirPerPage,
            searchKey: newVal,
            filtre: true,
            tablo: 'gelirListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
            // this.todayToplamKayit = res.aktifSayac;
            // this.totalRows = res.aktifKonuklar.length;
          })
    },
    giderSearch(newVal) {
      this.giderPage = 1
      this.isDate = true
      const veri = newVal * this.giderPerPage - this.giderPerPage
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            start: this.start,
            end: this.end,
            baslangic: 0,
            uzunluk: this.giderPerPage,
            searchKey: newVal,
            filtre: true,
            tablo: 'giderListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    konaklamaSearch(newVal) {
      this.konaklamaPage = 1
      this.isDate = true
      const veri = newVal * this.konaklamaPerPage - this.konaklamaPerPage
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            start: this.start,
            end: this.end,
            baslangic: 0,
            uzunluk: this.konaklamaPerPage,
            searchKey: newVal,
            filtre: true,
            tablo: 'konukListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    onlineSearch(newVal) {
      this.konaklamaPage = 1
      this.isDate = true
      const veri = newVal * this.onlinePerPage - this.onlinePerPage
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            start: this.start,
            end: this.end,
            baslangic: 0,
            uzunluk: this.onlinePerPage,
            searchKey: newVal,
            filtre: true,
            tablo: 'onlineListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    start(newVal) {
      this.gelirSearch = ''
      this.giderSearch = ''
      this.havaleSearch = ''
      this.konaklamaSearch = ''
      this.onlineSearch = ''
      if (newVal && this.end) {
        this.isDate = true
        const veri = newVal * this.gelirPerPage - this.gelirPerPage
        this.$store
            .dispatch('kasaList', {
              start: newVal,
              end: this.end,
              uzunluk: this.gelirPerPage,
              baslangic: veri,
              searchKey: this.gelirSearch,
              filtre: true,
            })
            .then(res => {
              this.$refs.refreshCard.showLoading = false
            })
        this.$store
            .dispatch('kasaListSayfala', {
              uzunluk: this.gelirPerPage,
              baslangic: 0,
              searchKey: this.gelirSearch,
              start: this.start,
              end: this.end,
              filtre: true,
              tablo:
                  this.currentTab == 0
                      ? 'gelirListesi'
                      : this.currentTab == 1
                          ? 'havaleListesi'
                          : this.currentTab == 2
                              ? 'giderListesi'
                              : this.currentTab == 3
                                  ? 'konukListesi'
                                  : this.currentTab == 4
                                      ? 'onlineListesi'
                                      : '',
            })
            .then(res => {
            })
      }
    },
    end(newVal) {
      this.gelirSearch = ''
      this.giderSearch = ''
      this.havaleSearch = ''
      this.konaklamaSearch = ''
      this.onlineSearch = ''
      if (newVal && this.start) {
        this.isDate = true
        this.$store
            .dispatch('kasaList', {
              start: this.start,
              end: newVal,
              uzunluk: this.gelirPerPage,
              baslangic: 0,
              searchKey: this.gelirSearch,
              filtre: true,
            })
            .then(res => {
              this.$refs.refreshCard.showLoading = false
            })
        this.$store
            .dispatch('kasaListSayfala', {
              uzunluk: this.gelirPerPage,
              baslangic: 0,
              searchKey: this.gelirSearch,
              start: this.start,
              end: newVal,
              filtre: true,
              tablo:
                  this.currentTab == 0
                      ? 'gelirListesi'
                      : this.currentTab == 1
                          ? 'havaleListesi'
                          : this.currentTab == 2
                              ? 'giderListesi'
                              : this.currentTab == 3
                                  ? 'konukListesi'
                                  : this.currentTab == 4
                                      ? 'onlineListesi'
                                      : '',
            })
            .then(res => {
              this.islem = false
            })
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('clearVardiyaOzeti')
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 10)
  },
  methods: {
    onlineShow() {
      this.gelirSearch = ''
      this.giderSearch = ''
      this.havaleSearch = ''
      this.konaklamaSearch = ''
      this.onlineSearch = ''
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.onlinePerPage,
            baslangic: 0,
            searchKey: '',
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'onlineListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    onlinePerPageChange(newVal) {
      const veri = this.onlinePage * newVal - newVal
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.onlineSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'onlineListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    onlineCurrentPageChange(newVal) {
      const veri = newVal * this.onlinePerPage - this.onlinePerPage
      this.islem = true
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.onlinePerPage,
            baslangic: veri,
            searchKey: this.onlineSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'onlineListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    konaklamaShow() {
      this.gelirSearch = ''
      this.giderSearch = ''
      this.havaleSearch = ''
      this.konaklamaSearch = ''
      this.onlineSearch = ''
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.giderPerPage,
            baslangic: 0,
            searchKey: '',
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'konukListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    konaklamaPerPageChange(newVal) {
      const veri = this.konaklamaPage * newVal - newVal
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.konaklamaSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'konukListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    konaklamaCurrentPageChange(newVal) {
      const veri = newVal * this.konaklamaPerPage - this.konaklamaPerPage
      this.islem = true
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.konaklamaPerPage,
            baslangic: veri,
            searchKey: this.konaklamaSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'konukListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    giderShow() {
      this.gelirSearch = ''
      this.giderSearch = ''
      this.havaleSearch = ''
      this.konaklamaSearch = ''
      this.onlineSearch = ''
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.giderPerPage,
            baslangic: 0,
            searchKey: '',
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'giderListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    giderPerPageChange(newVal) {
      const veri = this.giderPage * newVal - newVal
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.giderSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'giderListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    giderCurrentPageChange(newVal) {
      const veri = newVal * this.giderPerPage - this.giderPerPage
      this.islem = true
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.giderPerPage,
            baslangic: veri,
            searchKey: this.giderSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'giderListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    havaleShow() {
      this.gelirSearch = ''
      this.giderSearch = ''
      this.havaleSearch = ''
      this.konaklamaSearch = ''
      this.onlineSearch = ''
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.havalePerPage,
            baslangic: 0,
            searchKey: '',
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'havaleListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    havaleCurrentPageChange(newVal) {
      const veri = newVal * this.havalePerPage - this.havalePerPage
      this.islem = true
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.havalePerPage,
            baslangic: veri,
            searchKey: this.havaleSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'havaleListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    havalePerPageChange(newVal) {
      const veri = this.havalePage * newVal - newVal
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.havaleSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'havaleListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    gelirShow() {
      this.gelirSearch = ''
      this.giderSearch = ''
      this.havaleSearch = ''
      this.konaklamaSearch = ''
      this.onlineSearch = ''
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.gelirPerPage,
            baslangic: 0,
            searchKey: '',
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'gelirListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    gelirPerPageChange(newVal) {
      const veri = this.gelirPage * newVal - newVal
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.gelirSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'gelirListesi',
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
          })
    },
    gelirCurrentPageChange(newVal) {
      const veri = newVal * this.gelirPerPage - this.gelirPerPage
      this.islem = true
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('kasaListSayfala', {
            uzunluk: this.gelirPerPage,
            baslangic: veri,
            searchKey: this.gelirSearch,
            start: this.start,
            end: this.end,
            filtre: true,
            tablo: 'gelirListesi',
          })
          .then(res => {
            this.$refs.refreshCard.showLoading = false
          })
    },
    refreshStop() {
      this.filtre = false
      this.gelirPerPage = 20
      this.TperPage = 20
      this.baslangic = ''
      this.bitis = ''
      this.filter = ''
      this.filterT = ''
      this.islem = true
      this.$store
          .dispatch('konaklamaListActive', {
            uzunluk: this.gelirPerPage,
            baslangic: 0,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.islem = false
            this.todayToplamKayit = res.aktifSayac
            this.totalRows = res.aktifKonuklar.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    propDate(data) {
      if (data) {
        return data.slice(0, 16)
      }
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.my-table .custom-select {
  max-width: 90px !important;
}

.kasa-title {
  margin-left: 15px;
}

.col-4 {
  padding-left: 0 !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none !important;
}

.disabledColor {
  border-color: #cbc1eb !important;
  background-color: white;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white !important;
}

.table th,
.table td {
  white-space: nowrap;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 10px !important;
}

.table th,
.table td {
  padding: 0 !important;
  width: 800px !important;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

.table th,
.table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  /* background-color: #2e83c9; */
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

thead tr {
  white-space: nowrap !important;
  text-align: center !important;
  background-color: #0ea9d7 !important;
  color: white !important;
}

td {
  font-weight: 500;
}

.aciklama td {
  white-space: pre-wrap !important;
}

/* .card-header {
  display: none !important;
} */

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: transparent !important;
}

.table:not(.table-dark):not(.table-light)
thead:not(.thead-dark)
th[data-v-501c2df2],
.table:not(.table-dark):not(.table-light)
tfoot:not(.thead-dark)
th[data-v-501c2df2] {
  padding: 0 !important;
}

.table-sm th,
.table-sm td {
  padding: 0.1rem !important;
  font-weight: 500 !important;
}

.pull-up {
  transition: 200ms;
}

.myHr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.input-group > .custom-select:not(:first-child) {
  max-width: 90px !important;
}

.arama .input-group > .form-control:not(:first-child),
.arama .input-group > .custom-select:not(:first-child) {
  max-width: 190px !important;
}

.arama .input-group {
  position: relative;
  justify-content: end !important;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .input-group-prepend > .btn,
[dir="ltr"] .input-group > .input-group-prepend > .input-group-text,
[dir="ltr"]
.input-group:not(.has-validation)
> .input-group-append:not(:last-child)
> .btn,
[dir="ltr"]
.input-group:not(.has-validation)
> .input-group-append:not(:last-child)
> .input-group-text,
[dir="ltr"]
.input-group.has-validation
> .input-group-append:nth-last-child(n + 3)
> .btn,
[dir="ltr"]
.input-group.has-validation
> .input-group-append:nth-last-child(n + 3)
> .input-group-text,
[dir="ltr"]
.input-group
> .input-group-append:last-child
> .btn:not(:last-child):not(.dropdown-toggle),
[dir="ltr"]
.input-group
> .input-group-append:last-child
> .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #d8d6de !important;
}

.input-group-prepend {
  background-color: white;
}

.input-group-append {
  background-color: white;
}
</style>
